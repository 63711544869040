const ArrowSvg = () => {
    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6751_20970)">
          <path
            d="M1 3L5 7L9 3"
            stroke="#5F6E80"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_6751_20970">
            <rect
              width="10"
              height="10"
              fill="white"
              transform="translate(0 10) rotate(-90)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  };
  export default ArrowSvg;