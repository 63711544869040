import React from 'react'

const Loader = () => {
  return (
    <div className="loader">
      <div className="loadingio-spinner-reload-tbhhuwfj8na">
        <div className="ldio-scm1tro0qmg">
          <div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loader


