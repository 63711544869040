import { Country, State, City } from 'country-state-city';

export const getAllCountries=()=>{
    return Country.getAllCountries().map(country=>({name:country.name,countryCode:country.isoCode,phoneCode:country.phonecode}))
}

export const getAllSates=()=>{
    return State.getAllStates().map(i => ({ name: i.name, stateCode: i.isoCode, countryCode: i.countryCode}))
}

export const getAllSatesByCountry=(countryCode)=>{
    return State.getStatesOfCountry(countryCode).map(i => ({ name: i.name, stateCode: i.isoCode, countryCode: i.countryCode}))
}

export const getAllCitiesByState = (countryCode, stateCode =null)=>{
    if(stateCode){
      return  City.getCitiesOfState(countryCode, stateCode).map(i => ({ name: i.name, stateCode: i.stateCode, countryCode: i.countryCode }))
    }else{
        return City.getCitiesOfCountry(countryCode).map(i => ({ name: i.name, stateCode: i.stateCode, countryCode: i.countryCode }))
    }
}

export const getAllCities=()=>{{
    return City.getAllCities().map(i => ({ name: i.name, stateCode: i.stateCode, countryCode: i.countryCode }));
}}