import { default as ReactSelect } from "react-select";
import { ISelectLocationField } from "../../types/select-location";

const SelectComponent = ({ options, value, placeHolder, onChange, optionLabel, className, customSelectStyle, error, label, ...props }: ISelectLocationField) => {
  return (
    <div className={className || ''}>
      {
        label && <p className={label.className}>{label.name}</p>
      }
      <ReactSelect
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        options={options || []}
        placeholder={placeHolder || "Select"}
        {...(optionLabel && { getOptionLabel: (option) => option[`${optionLabel}`] })}
        styles={customSelectStyle}
        value={value || ''}
        onChange={onChange}
        {...props}
      />
      {
        error && <p className={error.className}>{error.message}</p>
      }
    </div>
  )
}

export default SelectComponent