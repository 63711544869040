import SelectComponent from './SelectComponent';
import { ISelectLocationField, SelectLocationProp } from '../../types/select-location';
import { getAllSates, getAllCountries, getAllSatesByCountry, getAllCitiesByState, getAllCities } from '../../utils/getCountryStateCity';
import { useEffect, useState } from 'react';

const SelectLocation = ({ fields, className, title }: SelectLocationProp) => {

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

   const countryField: ISelectLocationField | null  = fields.find( field => field.type==='country') ?? null;
   const stateField: ISelectLocationField | null = fields.find(field=>field.type==='state') ?? null;
   const cityField: ISelectLocationField | null = fields.find(field=>field.type==='city') ?? null;
    useEffect(() => {
		if (countryField?.selectedValue?.countryCode) {
			if(stateField){
                stateField.setSelectedValue(null);
                setStates(prev => getAllSatesByCountry(countryField.selectedValue.countryCode))
            }
		}
	}, [countryField && countryField?.selectedValue]);
    useEffect(()=>{
        if (stateField?.selectedValue){
            const city = getAllCitiesByState(stateField.selectedValue?.countryCode, stateField.selectedValue?.stateCode)
            setCities(prev =>city)
        }else{
            setCities(prev => getAllCities())
        }
    },[stateField?.selectedValue])

    useEffect(()=>{
        if(countryField && countryField.selectedValue) {
            setStates(prev => getAllSatesByCountry(countryField.selectedValue.countryCode));
        } else{ 
            setStates(prev => getAllSates());
        }
    },[]);

    return (
        <div className={className || ''}>
            {title &&
                <p className={title.className}>{title.name}</p>
            }
            {
                countryField && 
                <SelectComponent
                    options={getAllCountries()}
                    optionLabel= {countryField.optionLabel ?? 'name'}
                    placeHolder= {countryField.placeHolder ?? 'Select Country'}
                    value= {countryField.selectedValue || ''}
                    onChange={ (newValue) => {
                        if (typeof newValue != 'string') {
                            countryField.setSelectedValue(newValue);
                        }
                    }}
                    {...countryField}
             />
            }
            {
                stateField && 
                <SelectComponent
                    options={states}
                    optionLabel= {stateField.optionLabel ?? 'name'}
                    placeHolder= {stateField.placeHolder ?? 'Select state'}
                    value= {stateField.selectedValue || ''}
                    onChange={ (newValue) => {
                        if (typeof newValue != 'string') {
                            stateField.setSelectedValue(newValue);
                        }
                    }}
                    {...stateField}
             />
            }
            {cityField &&
                <SelectComponent
                    options={cities}
                    optionLabel={cityField.optionLabel ?? 'name'}
                    placeHolder={cityField.placeHolder ?? 'Select city'}
                    value={cityField.selectedValue || ''}
                    onChange={(newValue) => {
                        if (typeof newValue != 'string') {
                            cityField.setSelectedValue(newValue);
                        }
                    }}
                    {...cityField}
                />
            }

        </div>
    )
}

export default SelectLocation